.latex-body{
  position:relative;
  top:50px;
}

.notion { 
  padding-top: 3em !important;
  width: 100%;
  height: 50em; 
  border: 0px solid #ccc; 
  padding: none; 
} 