*{
    margin-top:0 !important;
}
.NavbarItems{
    color:black;
    height:80px;
    display: flex;
    position:relative;
    flex-wrap: wrap;
    justify-content:left;
    align-items:center;
    font-size: 1rem;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.blue-bar{
    width: 100%, important !important;;
    background-color: white;
    box-shadow: 0px 0px 20px 0px lightgrey;
    padding-top:20px;
    padding-right:6%;
    padding-left:6%;
    border-radius:0;
}

.navbar-logo{ 
    display: inline-block;
    align-items:left;
    padding-top: 0.3125rem;
    padding-bottom: 3.8rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.menu-icon{
    width:25px;
    height:3px;
    background: black;
    border-radius: 5px;
    transition: all .5s ease-in-out;
    
}
.menu-icon::before,
.menu-icon::after{
    content: '';
    position: absolute;
    width:25px;
    height:3px;
    background: black;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}
.menu-icon::before{
    transform: translateY(-8px);
}
.menu-icon::after{
    transform: translateY(8px);
}

.open .menu-icon{
    transform: translateX(-50px);
    background:transparent;
}

.nav-menu{
    display:grid;
    grid-template-columns: repeat(5,auto);
    grid-gap: 10px;
    list-style: none;
    text-align:center;
    width: 75vw;
    right: -25px;
    justify-content:right;
    position:relative;
    align-self:right;
}



.nav-links{
    text-decoration:none !important;
    color:inherit !important;
    color:black;
    display:inline-block;
    padding: .5rem 1rem;
    position: relative;
}
.nav-links::after{
    content: '';
    width: 70%;
    height: 1px;
    background-color: black;
    border-radius: 3px;
    position:absolute;
    left:15%;
    bottom:2px;
    transform:scale3d(0,1,1);
    transform-origin:100% 50%;
    transition: transform .5s ease;
}
.nav-links:hover::after{
    transform-origin:0% 50%;
    transform: scale3d(1,1,1);
}

.menu-icon{
    display:none;
}

.navbar-collapse {
    flex-basis: 50%;
    flex-grow: 1;
    align-items: center;
}

@media screen and (max-width: 800px) {
    .nav-menu{
      display: flex;
      background:rgba(0,0,0,0);
      flex-direction: column;
      width: 130%;
      height: 130vh !important;
      position: absolute;
      top: 100%;
      left: -100%;
      opacity: 1;
      transition: all .75s ease;
    }
    .nav-menu.active{
        background-color: white;
        top:100%;
        left:-20%;
        opacity:1;
        transition: all .75s ease;
        z-index: 1;
    }
    .nav-links{
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }
    .menu-icon{
        position:absolute;
        top:50%;
        right:6%;
        display:inline-block;
        align-self:right;
    }
    
    .menu-icon{
        display: block;
        font-size:1rem;
        cursor:pointer;
    }
  }
  @media screen and (max-width: 550px) {
    .nav-menu{
        display: flex;
        background:white;
        flex-direction: column;
        width: 120%;
        height: 100vh !important;
        position: absolute;
        top: 100%;
        left: -120%;
        opacity: 1;
        transition: all 0.5s ease;
      }
  }
