/*Texts floating in animation*/
a{
  color:black;
  font-weight:bold;
}
a:hover{
  color:black;
  text-decoration:none;
}
.title{
  position:relative;
  top:50px;
  padding-bottom:50px;
  color: black;
  line-height: 30px;
  margin-left:15%;
  margin-right:15%;
}
.space{
  padding-top:30px
}
.container{
  width:100%;
  padding-right:15px;
  padding-left:15px;
}
.row{
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-right:0px;
  margin-left:0px;
}
.col{
  margin-right:5% !important;
  margin-left:3% !important;
  text-align:justify;
  text-justify: inter-word;
  position: relative;
  flex-basis:0 ;
  flex-grow:1 ;
  max-width:100%;
}
.col2{
  top:60px;
  margin-right:3% !important;
  margin-left:5% !important;
  position: relative;
  flex-basis:0 ;
  flex-grow:1 ;
  max-width:30%;
}
.bold{
  font-weight: bold;
}
.title-content{
  display: block;
  opacity: 0;
  animation: revealText 1s forwards;
}
.title-second{
  animation-delay: .1s;
}
.title-third{
  animation-delay: .2s;
}
.title-fourth{
  animation-delay: .3s;
}
.title-fifth{
  animation-delay: .4s;
}
@keyframes revealText{
  from{
    transform: translateY(100px);
  }
  to{
    opacity: 1;
    transform: none;
  }
}
body{
  background-color: rgb(235,235,235);
}
*{
  font-family: ryo-text-plusn, menlo;
  font-weight: 500;
  font-style: normal;
}

/*animation for the ...*/

.one {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.0s;
  animation: dot 1.3s infinite;
  animation-delay: 0.0s;
}

.two {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.2s;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
}

.three {
  opacity: 0;
  -webkit-animation: dot 1.3s infinite;
  -webkit-animation-delay: 0.3s;
   animation: dot 1.3s infinite;
   animation-delay: 0.3s;
}


@-webkit-keyframes dot {
    0% { opacity: 0; }
   50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes dot {
    0% { opacity: 0; }
   50% { opacity: 0; }
  100% { opacity: 1; }
}
/*for RESUME*/
.react-pdf__Page__canvas {
  box-shadow: 5px 5px 5px 5px gray;
  margin: 0 auto;
  width:60% !important;
  height: 100% !important;
}
@media screen and (max-width: 1000px) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
  }
  .title{
    font-size:.7em;
    line-height: 25px;
  }
  h1{
    font-size:2em;
  }
  .col2{
    top:60px;
    margin-right:5% !important;
    margin-left:0% !important;
    position: relative;
    flex-basis:0 ;
    flex-grow:1 ;
    max-width:40%;
  }
}
@media screen and (max-width: 800px) {
  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
  }
  .makeTall{
    height:0px;
  }
  .title{
    font-size:.55em;
    line-height: 25px;
  }
}
@media screen and (max-width: 500px) {
  .title{
    font-size:.5em;
    line-height: 20px;
    margin-left:8%;
    margin-right:8%;
  }
  .container{
    width:100%;
    padding-right:0%;
    padding-left:0%;
  }
  h1{
    font-size:2.5em;
  }
  .col2{
    top:60px;
    margin-right:5% !important;
    margin-left:0% !important;
    position: relative;
    flex-basis:0 ;
    flex-grow:1 ;
    max-width:40%;
  }
  .space{
    padding-top:10px;
  }
}
.makeTall{
  height:50px;
}